import React, { useState, useEffect } from 'react';
import { MDXProvider } from '@mdx-js/react';
import { MDXRenderer  } from 'gatsby-plugin-mdx';
import shortcodes from './shortcodes';

const MyRenderer = ({ children }) => (
  <MDXProvider components={shortcodes}>
    <MDXRenderer>{children}</MDXRenderer>
  </MDXProvider>
);


export default MyRenderer;
